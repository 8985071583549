import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getBlogUrl } from "../../lib/helpers"
import styles from "../../styles/menuItem.module.css"

library.add(faPlus, faMinus)
const plusIcon = <FontAwesomeIcon icon="plus" />
const minusIcon = <FontAwesomeIcon icon="minus" />

const TitleDiv = styled.div`
  background-color: #19232d;
  color: #ffffff;
`

const PostTitle = styled.div`
  background-color: #19232d;
  display: grid;
  grid-template-columns: 25fr 1fr;
  justify-items: start;
  cursor: pointer;
`

const TitleName = styled.span`
  font-family: "Noe Display";
  font-weight: bold;
  padding: 0;
`

const PostSubTitle = styled.div`
  background-color: #19232d;
  width: 80%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

const IconDiv = styled.div`
  cursor: pointer;
  font-size: 1.5rem;
  align-self: center;
`

const LocalLink = styled(Link)`
  list-style-type: none;
  text-decoration: none;
  color: white;
`

const Ul = styled.ul`
  list-style-type: none;
`

class MenuItem extends React.Component {
  handleClick = name => {
    const { onClick } = this.props
    onClick(name)
  }

  render() {
    const { name, value, color } = this.props.data
    const categoryWithoutList = [
      "Welcher Schlaftyp bist du?",
      "Zur Übersicht",
      "Mach den Selbsttest über Schlafstörungen"
    ]

    const BlogTitle = styled.li`
      &:hover {
        cursor: pointer;
        text-decoration: underline;
        color: ${color};
      }
    `

    const getListOfBlogTitle = () => {
      return value
        .filter(elem => elem.subItemReference != null)
        .map((item, i) => (
          <LocalLink
            key={i}
            to={`/${getBlogUrl(
              item.subItemReference.parenttag.slug.current,
              item.subItemReference.slug.current,
              item.subItemReference.articleTier
            )}`}
          >
            <Ul key={i}>
              <BlogTitle key={item.subItemName}>{item.subItemName}</BlogTitle>
            </Ul>
          </LocalLink>
        ))
    }

    return (
      <TitleDiv
        className={styles.title}
        onClick={() => this.handleClick(name)}
        style={{ color: this.props.selected === name ? color : "white" }}
      >
        {categoryWithoutList.includes(name) ? (
          <PostTitle className={styles.title}>
            <LocalLink to="/">{name}</LocalLink>
          </PostTitle>
        ) : (
          <PostTitle>
            <TitleName>{name} </TitleName>
            {this.props.selected === name ? (
              <IconDiv>{minusIcon}</IconDiv>
            ) : (
              <IconDiv>{plusIcon}</IconDiv>
            )}
          </PostTitle>
        )}
        {this.props.selected === name && (
          <PostSubTitle className={styles.PostSubTitle}>{getListOfBlogTitle()}</PostSubTitle>
        )}
      </TitleDiv>
    )
  }
}

export default MenuItem
