import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Menu from "./menu"

const MenuQuery = props => (
  <StaticQuery
    query={graphql`
      query {
        allSanityMenuitem {
          edges {
            node {
              menuItemTitle
              favoriteColor {
                hex
              }
              subItemArray {
                subItemName
                subItemReference {
                  title
                  slug {
                    current
                  }
                  articleTier
                  parenttag {
                    title
                    slug {
                      current
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Menu {...props} data={data.allSanityMenuitem.edges} />}
  />
)

export default MenuQuery
