export function cn(...args) {
  return args.filter(Boolean).join(" ")
}

export function mapEdgesToNodes(data) {
  if (!data.edges) return []
  return data.edges.map(edge => edge.node)
}

export function mapEdgesToNodes2(data) {
  if (!data.edges) return []
  return data.edges.map(edge => edge.node)
}

export function getBlogUrl(parenttag, slug, articleTier) {
  var path
  switch (articleTier) {
    case "tier1":
      path = `${parenttag || parenttag}/`
      break
    case "tier3":
      path = `${parenttag || parenttag}/${slug.current || slug}/`
      break
    default:
      path = `${slug.current || slug}/`
  }
  return path
}

export function buildImageObj(source) {
  /*
   * The third parameters ("") added to the imageObj is only temporary
   * until the resolver is fix.
   */
  const imageObj = {
    asset: { _ref: source.asset._ref || source.asset._id || "" }
  }

  if (source.crop) imageObj.crop = source.crop
  if (source.hotspot) imageObj.hotspot = source.hotspot

  return imageObj
}

export function getPostByCategory(arrGroup, categoryName) {
  return arrGroup.filter(element => element.category.catSlug === categoryName).slice(0, 5)
}

export const sortByHighestNumber = property => (a, b) => b[property] - a[property]

export const filterData = menuItems => {
  const filterList = ["Testeintrag"]
  return menuItems.filter(menuItem => !filterList.includes(menuItem.name))
}

export const sortData = property => {
  let sortOrder = 1

  // To sort in a descending order
  if (property[0] === "-") {
    sortOrder = -1
    property = property.substr(1)
  }

  return (a, b) =>
    sortOrder === -1
      ? b[property].localeCompare(a[property])
      : a[property].localeCompare(b[property])
}

export const moveAndChange = (arr, from, to) => {
  // Used to change element position in an array
  const cutOut = arr.splice(from, 1)[0] // cut the element at index 'from'
  return arr.splice(to, 0, cutOut) // insert it at index 'to'
}
