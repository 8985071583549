import React, { Component } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import MenuItem from "./menuItem"
import * as ROUTES from "../../constants/routes"
import { filterData, sortData, moveAndChange } from "../../lib/helpers"
/* import facebookw from "../assets/facebook-w.svg"
import instagramw from "../assets/instagram-w.svg"
import pinterestw from "../assets/pinterest-w.svg"
import twitterw from "../assets/twitter-w.svg" */
import bslogow from "../../assets/bs-logo-w.svg"
import cancel from "../../assets/cancel.svg"
import styles from "../../styles/menu.module.css"

const MenuContainer = styled.div`
  display: grid;
  grid-template-rows: 100px 1fr;
  align-items: top;
  grid-template-columns: 1fr 10fr 1fr;
  background-size: cover;
  background-color: #19232d;
  color: #ffffff;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 100;
  overflow-y: scroll;
`

const PostHeading = styled.div`
  grid-row: 1 / span 1;
  grid-column: 1 / span 3;
  background: #19232d;
  display: grid;
  grid-template-columns: 1fr 35fr;
  align-items: center;
  height: 100px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 98;
`

const MenuHeaderLine = styled.hr`
  grid-row: 2 / span 1;
  grid-column: 1 / span 3;
  width: 100%;
  height: 1.5px;
  background-color: #ffffff;
  border: none;
  margin-top: 0;
  opacity: 0.42;
  z-index: 90;
`

const ThirdDivFormat = styled.div`
  grid-row: 2 / span 1;
  grid-column: 2 / span 1;
  background-color: #19232d;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
`

const DefaultPageLogo = styled.img`
  grid-column: 1 / span 1;
  cursor: pointer;
  height: 3.5rem;
  padding-left: 1em;
  &:hover {
    cursor: pointer;
  }
`

const CancelDiv = styled.div`
  grid-column: 3 / span 1;
  display: grid;
  grid-template-columns: 2fr 10px 1fr;
  justify-items: space-between;
  width: 98.9%;
  height: 4rem;
  z-index: 100;
  transition: 0.5s;
`

const Schliesen = styled.div`
  grid-column: 1 / span 1;
  align-self: center;
  font-size: 24px;
  font-weight: 600;
  font-family: overpass;
  color: #ffffff;
  &:hover {
    cursor: pointer;
    color: red;
  }
`

const CancelIcon = styled.img`
  grid-column: 3 / span 1;
  width: 25px;
  height: 25px;
  align-self: center;
  &:hover {
    cursor: pointer;
  }
`

const TitleCategory = styled.div``

const SocialMediaDiv = styled.div``

/* const SocialIcon = styled.img`
  &:hover {
    cursor: pointer;
  }
`

const SocialLink = styled.a`
  list-style-type: none;
`
*/
class Menu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inputValue: "",
      color: "white",
      listOfMenu: this.generateListOfMenu(),
      selected: "",
      displayMenu: true
    }
  }

  generateListOfMenu = () => {
    let data = this.transformMenuList()
    data = filterData(data)
    data.sort(sortData("name")) // To sort in descending order add '-' to the property Example data.sort(sortData('-name'))
    moveAndChange(data, 0, 4) // This changes 'Object' position in the array
    moveAndChange(data, 0, 1)
    return data
  }

  transformMenuList = () => {
    const { data } = this.props
    return data.map(({ node }) => ({
      name: node.menuItemTitle,
      value: node.subItemArray,
      color: node.favoriteColor.hex
    }))
  }

  handleInputChange = ({ target: { value } }) => {
    this.setState({ inputValue: value })
  }

  handleQuizClick = () => {
    navigate(ROUTES.SIGN_IN)
  }

  handleSubmit = event => {
    return this.state.inputValue === "" ? event.preventDefault() : this.state.inputValue
  }

  togglePostList = name => {
    if (this.state.selected === name) {
      this.setState({ selected: "" })
    } else {
      this.setState({ selected: name })
    }
  }

  handleCloseMenu = () => {
    this.setState({ displayMenu: false })
    this.props.onClick({ showMenu: false })
  }

  render() {
    const menu = (
      <MenuContainer>
        <PostHeading>
          <DefaultPageLogo src={bslogow} alt="bs-logo" onClick={this.handleCloseMenu} />
          <CancelDiv className={styles.displayMenuCancel}>
            <Schliesen id="menuCancel" onClick={this.handleCloseMenu}>
              SCHLIESSEN
            </Schliesen>
            <CancelIcon
              id="menuCancel"
              className={styles.cancel}
              src={cancel}
              alt="close"
              onClick={this.handleCloseMenu}
            />
          </CancelDiv>
        </PostHeading>
        <MenuHeaderLine />
        <ThirdDivFormat>
          <TitleCategory>
            {this.state.listOfMenu.map((data, i) => (
              <div key={i}>
                <MenuItem
                  id={data.name}
                  key={data.name}
                  data={data}
                  onClick={
                    data.name === "Mach den Selbsttest über Schlafstörungen"
                      ? this.handleQuizClick
                      : this.togglePostList
                  }
                  selected={this.state.selected}
                />
              </div>
            ))}
          </TitleCategory>
        </ThirdDivFormat>
        <SocialMediaDiv className={styles.SocialMediaDiv}>
          {/*           <SocialLink as="a" href="https://www.facebook.com/besserschlafen.de/">
            <SocialIcon src={facebookw} alt="facebook-logo" />
          </SocialLink>
          <SocialLink as="a" href="https://www.twitter.com/besserschlafen">
            <SocialIcon src={twitterw} alt="twitter-logo" />
          </SocialLink>
          <SocialLink as="a" href="https://www.pinterest.com/besserschlafen">
            <SocialIcon src={pinterestw} alt="pinterest-logo" />
          </SocialLink>
          <SocialLink as="a" href="https://www.instagram.com/besserschlafen.de">
            <SocialIcon src={instagramw} alt="instagram-logo" />
          </SocialLink> */}
        </SocialMediaDiv>
      </MenuContainer>
    )
    return <div>{this.state.displayMenu && menu}</div>
  }
}

export default Menu
