import React from "react"
import MenuQuery from "../components/main/menuQuery"

const MenuPage = props => {
  return (
    <div>
      <MenuQuery {...props} />
    </div>
  )
}
export default MenuPage
